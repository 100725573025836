import 'ion-rangeslider/js/ion.rangeSlider.js';
import * as $ from 'jquery';

export class RangeSlider {
    createRangeSliderByRef(ref: any, context: any) {
        let props = context.sliderProperties;
        if (context.onRangeChange) {
            props.onChange = function (data: any) {
                context.caller
                    .invokeMethodAsync(context.onRangeChange, data.from, data.to);
            };
        }
        if (context.onRangeFinish) {
            props.onFinish = function (data: any) {
                context.caller
                    .invokeMethodAsync(context.onRangeFinish, data.from, data.to);
            };
        }
        ($(ref) as any).ionRangeSlider(props);
    }
}