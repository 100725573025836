import { Editor } from '@tiptap/core';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import StarterKit from '@tiptap/starter-kit'
import { smallTextMark, unSelectByEnter, disableDefaultPtag } from './TextEditorExtensions';

export class TextEditor {
    private editor?: Editor;

    createTextEditorByRef(ref: HTMLElement, context: any) {
        let visualTitleElement = document.querySelector(`.edit-${context.editClass}`)!;

        let bubbleMenu = BubbleMenu.configure({
            pluginKey: 'bubbleMenuOne',
            element: ref
        });        

        this.editor = new Editor({
            element: visualTitleElement,
            extensions: [StarterKit, smallTextMark, bubbleMenu, unSelectByEnter, disableDefaultPtag],
            content: context.text,
            onUpdate: ({ editor }) => {
                let visualTitle = editor?.getHTML();
                context.onEditTextCaller.invokeMethodAsync(context.onEditTextFn, visualTitle);
            },
            autofocus: true
        });

        this.editor.setEditable(true);
        return this;
    }

    makeTextBold() {
        this.editor?.chain().focus().toggleBold().run();
    }

    makeTextItalic() {     
        this.editor?.chain().focus().toggleItalic().run();
    }

    makeTextSmall() {
        this.editor?.chain().focus().toggleMark('small-text').run();
    } 
}