import { RangeSlider } from './RangeSlider';

export function LoadRangeSlider() {
    (window as any).RangeSliderEngine = {
        createRangeSliderByRef: function (ref: any, context: any) {
            let slider = new RangeSlider().createRangeSliderByRef(ref, context);
            (window as any).ItemsManager.add(ref, slider);
        },

        destroyRangeSliderByRef: function (ref: any) {
            let manager = (window as any).ItemsManager;
            let rangeSlider = manager.get(ref);
            if (rangeSlider) {
                manager.remove(ref);
                rangeSlider.destroy();
            }
        }
    }
}