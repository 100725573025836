import { LoadRangeSlider } from './RangeSliderEngine';
import { LoadTextEditor } from './TextEditorEngine';

namespace SignalHub.Browser.Utils {
    export function Main() {
        (window as any).ItemsManager = {
            items: [],
            add: function (ref: any, visual: any) {
                this.items.push({ ref: ref, visual: visual });
            },
            get: function (ref: any) {
                let item = this.items.find((v: any) => v.ref == ref);
                return item && item.visual ? item.visual : null;
            },
            remove: function (ref: any) {
                let index = this.items.indexOf(this.items.find((v: any) => v.ref == ref))
                this.items.splice(index, 1);
            }
        };

        (window as any).Modal = {
            show: function (ref: any) {
                ref.modal('show');
            },
            hide: function (ref: any) {
                ref.modal('hide');
            }
        };

        (window as any).DownloadFile = {
            downloadFromUrl: function (url: string, fileName?: string): boolean {
                let error = false;
                try {
                    const anchorElement = document.createElement('a');
                    anchorElement.href = url;
                    anchorElement.target = "_blank";
                    anchorElement.download = fileName ?? '';
                    anchorElement.click();
                    anchorElement.remove();
                }
                catch (ex) {
                    error = true;
                }
                return error;
            }
        };

        (window as any).Locale = {
            getLanguage() {
                return navigator.language || (navigator.languages && navigator.languages.length && navigator.languages[0]) || 'en'
            },

            getShort(language: any) {
                if (language.includes('nb')) return 'nb';
                if (language.includes('no')) return 'nb';
                if (language.includes('sv')) return 'sv';
                if (language.includes('da')) return 'da';
                if (language.includes('fi')) return 'fi';
                if (language.includes('ru')) return 'ru';
                return "default";
            },

            getBigNumberPrefixes: function (customLanguage: any) {
                let language = customLanguage ?? this.getShort(this.getLanguage());
                switch (language) {
                    case 'nn':
                    case 'nb':
                    case 'no':
                        return { billion: 'mrd.', million: 'mill.', thousand: 'T' };
                    case 'sv':
                        return { billion: 'mdr', million: 'm', thousand: 'T' };
                    case 'da':
                        return { billion: 'mia.', million: 'mio.', thousand: 'T' };
                    case 'fi':
                        return { billion: 'mrd.', million: 'milj.', thousand: 'T' };
                    case 'ru':
                        return { billion: 'млрд', million: 'млн', thousand: 'тыс' };
                    default:
                        return { billion: 'B', million: 'M', thousand: 'T' };
                }
            }
        };

        (window as any).Clipboard = {
            copyText: async function (text: string) : Promise<boolean> {
                try {
                    await navigator.clipboard.writeText(text);
                    return true;
                }
                catch (err) {
                    console.error('Failed to copy: ', err);
                    return false;
                }
            }
        }
    }
}

namespace SignalHub.Browser.Controls.RangeSlider {
    export function Main() {
        LoadRangeSlider();
    }
}

namespace SignalHub.Browser.Controls.TextEditor {
    export function Main() {
        LoadTextEditor();
    }
}


SignalHub.Browser.Utils.Main();
SignalHub.Browser.Controls.RangeSlider.Main();
SignalHub.Browser.Controls.TextEditor.Main();
