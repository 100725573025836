import { Node, Mark, Extension, Editor, mergeAttributes } from '@tiptap/core'

export const disableDefaultPtag = Node.create({
    name: 'doc',
    topNode: true,
    content: 'text*',
})

export const smallTextMark = Mark.create({
    name: "small-text",
    toDOM: () => {
        return ["span", { class: "small-text" }, 0];
    },
    renderHTML({ HTMLAttributes }) {
        return ["span", mergeAttributes({ class: "small-text" }, HTMLAttributes), 0];
    },
    parseHTML() {
        return [{ tag: "span.small-text" }];
    },
});

export const blurCommand = () => ({ editor }: { editor: Editor }) => {
    editor.view.dom.blur()
    return true
}

export const unSelectByEnter = Extension.create({
    name: 'enter',

    addKeyboardShortcuts() {
        return {
            Enter: () => {
                return blurCommand()(this)
            },
        }
    },
})