import { TextEditor } from './TextEditor';

export function LoadTextEditor() {
    (window as any).TextEditorEngine = {
        createTextEditorByRef: function (ref: any, context: any) {
            let textEditor = new TextEditor().createTextEditorByRef(ref, context);
            (window as any).ItemsManager.add(ref, textEditor);
        },
        makeTextBoldByRef: function (ref: any) {
            let manager = (window as any).ItemsManager;
            let textEditor = manager.get(ref);
            textEditor?.makeTextBold();
        },
        makeTextItalicByRef: function (ref: any) {
            let manager = (window as any).ItemsManager;
            let textEditor = manager.get(ref);
            textEditor?.makeTextItalic();
        },
        makeTextSmallByRef: function (ref: any) {
            let manager = (window as any).ItemsManager;
            let textEditor = manager.get(ref);
            textEditor?.makeTextSmall();
        },
    }
}